<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-card class="mt-5">
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{ $vuetify.lang.t('$vuetify.contract.list') }}
        <v-spacer></v-spacer>

        <v-btn
          class="mx-1"
          color="error"
          dark
          small
          @click="handleDeleteClick"
        >
          {{ $vuetify.lang.t('$vuetify.common.delete') }}
        </v-btn>

        <v-btn
          class="mx-1"
          color="primary"
          dark
          small
          @click="handleCreateClick"
        >
          {{ $vuetify.lang.t('$vuetify.common.create') }}
        </v-btn>

      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="contractItems"
          item-key="id"
          show-select
          single-select
          hide-default-footer
        >

          <template
            v-slot:item.no="{ item }"
          >
            {{ item.equipmentTemp.no }}
          </template>

          <template v-slot:item.serviceType="{ item }">
            <span
              v-for="(service, index) in item.serviceList"
              :key="`service-${ service.id }`"
            >
              <span
                v-if="index !== 0"
              >
              ,
              </span>
              <span
                v-if="service.serviceType === 'REPORT_SERVICE'"
              >
                {{$vuetify.lang.t(`$vuetify.serviceItem.${ snakeToCamel(service.serviceIterm.toLowerCase()) }`)}}
              </span>
              <span
                v-else
              >
                {{$vuetify.lang.t(`$vuetify.serviceType.${ snakeToCamel(service.serviceType.toLowerCase()) }`)}}
              </span>
            </span>
          </template>

          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | moment('YYYY-MM-DD HH:mm') }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2"
              color="info"
              small
              @click="handleEditItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>

        </v-data-table>
        <v-pagination
          v-model="queryParams.params.page"
          :length="queryParams.params.pageCount"
        />
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $vuetify.lang.t('$vuetify.common.warning') }}
        </v-card-title>
        <v-card-text
          v-if="selected.length > 0"
        >
          {{ $vuetify.lang.t('$vuetify.message.deleteConfirm') }}
          <Strong>
            {{ selected[0].name }}
          </Strong>
          ?
        </v-card-text>
        <v-card-text
          v-else
        >
          {{ $vuetify.lang.t('$vuetify.message.needSelectedOne') }}
        </v-card-text>
        <v-card-actions
          v-if="selected.length > 0"
        >
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.cancel') }}
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="handleDeleteDialog"
          >
            {{ $vuetify.lang.t('$vuetify.common.delete') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else
        >
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            {{ $vuetify.lang.t('$vuetify.common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  import { snakeToCamel } from '@/utils';

  export default {
    components: {
      SearchForm: () => import('./SearchForm')
    },
    data () {
      return {
        selected: [],
        contractItems: [],
        editItem: null,
        deleteDialog: false,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        }
      }
    },

    computed: {

      ...mapGetters({
        contracts: 'contract/contracts'
      }),

      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.contract.contractName'),
            align: 'start',
            sortable: false,
            value: 'contractName',
          },{
            text: this.$vuetify.lang.t('$vuetify.equipmentTemplate.no'),
            align: 'start',
            sortable: false,
            value: 'no',
          },{
            text: this.$vuetify.lang.t('$vuetify.contract.serviceType'),
            align: 'start',
            sortable: false,
            value: 'serviceType',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.createdAt'),
            align: 'start',
            sortable: false,
            value: 'createdAt',
          },{
            text: this.$vuetify.lang.t('$vuetify.contract.operator'),
            align: 'start',
            sortable: false,
            value: 'operator',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.actions'),
            align: 'start',
            sortable: false,
            value: 'actions',
          }
        ]
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.contractQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
              this.contractItems = this.contracts
            })
         }
      }
    },

    created () {

      let params = Object.assign({}, this.queryParams.params)
      params.page--
      this.contractQuery({
        params: params,
        data: {}
      }).then( (res) => {
        this.queryParams.params.pageCount = res.totalPages
        this.contractItems = this.contracts
      })

    },

    methods: {

      ...mapActions({
        contractQuery: 'contract/query',
        contractDelete: 'contract/delete'
      }),

      snakeToCamel,

      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.contractQuery({params: params, data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
          this.contractItems = this.contracts
        })
      },

      handleCreateClick () {
        this.$router.push( { name: 'contractCreate' } )
      },

      handleEditItem (item) {
        this.$router.push( {name: 'contractEdit', params: { id: item.id + '' } })
      },

      handleDeleteClick () {
        this.deleteDialog = true
      },

      handleDeleteDialog () {
        this.contractDelete(this.selected[0]).then( () => {
          this.deleteDialog = false
          this.contractItems = this.contracts
        })
      }
    }
  }
</script>
